<template>
  <div class>
    <router-view />
    <van-tabbar route>
      <van-tabbar-item replace to="/lookIndex">
        <span>看看</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/schoolIndex">
        <span>名校</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <!-- <van-tabbar-item replace to="/periIndex">
        <span>周边</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item> -->
      <van-tabbar-item replace to="/circleIndex">
        <span>圈子</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="">
        <span>服务</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/myIndex">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon4.active : icon4.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      icon: {
        active:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/look2.png",
        inactive:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/look.png",
      },
      icon1: {
        active:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/school2.png",
        inactive:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/school.png",
      },
      icon2: {
        active:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/sircle2.png",
        inactive:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/sircle.png",
      },
      icon3: {
        active:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/fw2.png",
        inactive:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/fw.png",
      },
      icon4: {
        active:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/my2.png",
        inactive:
          "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/my.png",
      },
      msgTotal: sessionStorage.getItem("timMsgTotal"),
    };
  },
  mounted() {
    let user = this.$store.state.user;
    if (user.circleAuth == 2) {
      // SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能。
      let onSdkReady = function(event) {
        console.log("index-onSdkReady",event)
        this.getMsgTotal();
      };
      this.tim.on(this.Tim.EVENT.SDK_READY, onSdkReady, this);
      //SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态。
      // let onSdkNotReady = function(event) {
      //   console.log("index-onSdkNotReady",event)
      //   this.imLogin()
      // };
      // this.tim.on(this.Tim.EVENT.SDK_NOT_READY, onSdkNotReady, this);
      //当前单聊和群聊会话的未读总数
      let onTotalUnreadMessageCountUpdated = function(event) {
        this.msgTotal = event.data;
      };
      this.tim.on(this.Tim.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, onTotalUnreadMessageCountUpdated, this);
      this.imLogin();
    }
  },
  // destroyed() {
  //   this.tim.logout();
  // },
  methods: {
    imLogin() {
      let user = this.$store.state.user;
      if (user.circleAuth == 2 && !this.tim.isReady()) {
        let loginPromise = this.tim.login({userID: user.id + "", userSig: user.imSig});
        loginPromise.then(function (imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log(imResponse.data.errorInfo);
          }
        }).catch(function (imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
      }
    },
    getMsgTotal() {
      if (this.tim.isReady()) {
        let messageCount = this.tim.getTotalUnreadMessageCount();
        sessionStorage.setItem("timMsgTotal", messageCount + "")
        console.log(messageCount)
      }
    }

  },
};
</script>

<style scoped>
/deep/.van-tabbar-item {
  color: #d5d9e4;
  font-size: 20px;
}
/deep/.van-tabbar-item--active {
  color: #0957c3;
}
</style>
